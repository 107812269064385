import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MaterialIcon, {colorPalette} from 'material-icons-react';
// import { removeUserSession, getUserName } from '../utils/common';
const TopHeader = (props) => {

  const [userName, setUserName] = useState('Admin');
  const logout = ()=>{
    //removeUserSession();
    window.location = '/login';
  }

  return (
    <div>
      <nav className="main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
        <div class="container-fluid" style={{paddingLeft: 20, paddingRight:20}}>
          <NavLink exact to="/dashboard" class="navbar-brand">
          <img src="/dist/img/TechM.png" height="40" alt="SIMS Logo"></img>&nbsp;
          <span class="brand-text font-weight-bold">SIMS</span>&nbsp;&nbsp;
          </NavLink>
          <button
            class="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav navBar">
              <li class="nav-item">
                <NavLink exact to="/dashboard" className="nav-link">
                  Dashboard
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/inwardgoods" className="nav-link">
                  Inward Goods
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/goodsissuance" className="nav-link">
                  Goods Issue
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Outward</a>
                <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                  <NavLink exact to="/outwardgoods" className="nav-link">
                    Outward Goods
                  </NavLink>
                  <NavLink exact to="/outwardReturns" className="nav-link">
                    Outward Returns
                  </NavLink>
                </ul>
              </li>
              {/* <li class="nav-item">
                <NavLink exact to="/outwardgoods" className="nav-link">
                  Outward Goods
                </NavLink>
              </li> */}
              <li class="nav-item">
                <NavLink exact to="/inventory" className="nav-link">
                  Inventory
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/damagedinventory" className="nav-link">
                  Damaged Inventory
                </NavLink>
              </li>
              <li class="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Masters</a>
                <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                  {/* <NavLink exact to="/addBarn" className="nav-link">
                    Add Barn
                  </NavLink>
                  <NavLink exact to="/addBatch" className="nav-link">
                    Add Batch
                  </NavLink> */}
                </ul>
              </li>
            </ul>
          </div>

          <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            
            <li class="nav-item dropdown">
              <a class="nav-link alertIcon" data-toggle="dropdown" href="#">
                {/* <i class="far fa-bell"></i> */}
                <MaterialIcon icon='notifications_none' size={24} />
                <span class="badge badge-warning navbar-badge">15</span>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> 4 new messages
                  <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
            <li className="loggedas">Logged as: </li>
            <li class="nav-item dropdown">
              <a class="nav-link loggedasButton" data-toggle="dropdown" href="#">
                {userName} <i class="fa fa-caret-down" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                {/* <a href="#" class="dropdown-item">
                  <i class="fas fa-envelope mr-2"></i> View Profile
                  
                </a> */}
                <span href="#" class="dropdown-item" onClick={logout}>
                  <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default TopHeader;
