import React from "react";
import logo from "./logo.svg";
import "./App.css";

import TopHeader from "./components/layout/TopHeader";
import TopFooter from "./components/layout/TopFooter";
import Dashboard from "./components/pages/Dashboard";
import Login from './components/pages/Login';
import Inward from "./components/pages/Inward";
import Outwards from "./components/pages/Outward";
import DamagedInventory from "./components/pages/DamagedInventory";
import Inventory from "./components/pages/Inventory";
import GoodsIssuance from "./components/pages/GoodsIssuance";
import OutwardReturns from "./components/pages/OutwardReturns";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/login" component={Login}></Route>
          <div class="wrapper">
            <TopHeader />
            <div className="content myappcontent">
              <div className="container-fluid">
                <Route exact path="/dashboard" component={Dashboard}></Route>
                <Route exact path="/inwardgoods" component={Inward}></Route>
                <Route exact path="/goodsissuance" component={GoodsIssuance}></Route>
                <Route exact path="/outwardgoods" component={Outwards}></Route>
                <Route exact path="/inventory" component={Inventory}></Route>
                <Route exact path="/damagedInventory" component={DamagedInventory}></Route>
                <Route exact path="/outwardReturns" component={OutwardReturns}></Route>
              </div>
            </div>
            {/* <TopFooter /> */}
          </div>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
